import React, {useState, useEffect} from "react";
import './App.css';

function App() {
  const [quo, setQuo] = useState('');

  const getQuo = () =>{
    // fetch("https://api.adviceslip.com/advice")
    // .then(res => res.json())
    // .then(data => {console.log(data.slip.advice);
    // })
    fetch("https://type.fit/api/quotes")
    .then(res => res.json())
    .then(data => {
      let ranNum = Math.floor(Math.random() * data.length);
      setQuo(data[ranNum]);
    })
  }

  useEffect(()=>{
    getQuo();
  },[]);

  return (
    <div className="App">
      <div className="Card">
        <p>{quo.text}</p>
        <p>#{quo.author ? quo.author : 'Unknown'}</p>
        <input type="button" value="Random Quote" onClick={getQuo} />
      </div>
    </div>
  );
}

export default App;
